import * as types from './mutation-types';
import Vue from 'vue'

export default {

  /**
   * Установка данных приложения Б24
   * @param state
   * @param bxData
   */
  [types.SET_APP_BX_DATA](state, bxData) {
    state.app.bx = bxData;
  },

  /**
   * Установка конфига приложения
   * @param state
   * @param conf
   */
  [types.SET_CONFIG](state, conf) {
    console.log(`Mutate config`, conf)
    Vue.set(state.app, 'conf', conf);
  },

  /**
   * Установка конфига приложения
   * @param state
   * @param conf
   */
  [types.SET_CONFIG](state, conf) {
    Vue.set(state.app, 'conf', conf);
  },

};
