import randomstring from 'randomstring'
import _ from 'lodash'
import axios from 'axios'
import settings from './Settings'
import queryString from 'query-string'

/**

 */
class Request {
  constructor (command, params = {}) {
    this.command = command
    this.params = params
    this.id = this._randStr()
    this._data = [] // сюда складываются результирующие данные
  }

  /**
   * Фабричный метод
   */
  static create (...args) {
    return new Request(...args)
  }

  /**
   * произвольная строка из букв, длинной 12
   * @private
   */
  _randStr () {
    return randomstring.generate({
      length: 12,
      charset: 'alphabetic'
    })
  }

  get encodedParams() {
    return queryString.stringify(this.params)
  }

  /**
   * Выполнить запрос
   */
  async execute () {
    console.log(`this.params`, this.params)
    const response = await axios.post(
      settings.endpointUrl + this.command,
      queryString.stringify(this.params),
    )

    const result = _.get(response, 'data')

    if (_.isString(result)) throw new Error(`Unexpected server answer: ${response}`)
    if(_.get(result, 'error')) throw new Error(`Request error: ${_.get(result, 'error.description')}`)

    return _.get(result, 'data')
  }

  /**
   * Данные запроса
   */
  get data() {
    return this._data || [];
  }
}

export default Request
