import { log } from './../plugins/logger/index';
import _ from 'lodash';
import { Request as IuApiRequest } from "../plugins/iu-rest/iu";
import { Request as BxApiRequest, BX24 } from '../plugins/b24/iu/index';
import * as types from './mutation-types';

/**
 * Инициализация авторизационных данных приложения Б24
 * @param commit
 * @returns {Promise<void>}
 */
export const initB24AppData = async ({ commit }) => {
  const authData = BX24.getAuth() || await new Promise(resolve => {
    BX24.init(() => resolve(BX24.getAuth()))
  });
  commit(types.SET_APP_BX_DATA, authData)
}

/**
 * Сохраняет конфиг введенный юзером
 * @param state
 * @param commit
 * @param username
 * @param password
 * @returns {Promise<void>}
 */
export const saveUserConfig = async ({ commit, getters }, { username, password, from, translit }) => {
  log.debug('Start saving user config')
  console.log(commit)
  console.log(getters)
  console.log(username)
  console.log(password)
  console.log(from)
  console.log(translit)
  console.log(_)
  console.log(IuApiRequest)
  console.log(BxApiRequest)

/*  const ccUserData = await IuApiRequest.create('cloudcall.login', {
    username,
    password,
    member_id: getters.memberId
  }).execute()
  commit(types.SET_USER_CC_DATA, ccUserData)*/
  log.debug('Finishing CC logging in')
}

/**
 * Получение баланса
 * @param commit
 * @param getters
 * @returns {Promise<void>}
 */
export const loadBalance = async ({ commit, getters }) => {
  console.log(commit)
  console.log(getters)
}

