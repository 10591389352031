import Vue from 'vue'
import * as IuApi from './iu/index'

/**
 * Плагин iuRest, функциональность запросов к бэку
 */
const iuRest = {
  install (Vue) {
    Vue.$iuRest = IuApi
    Vue.prototype.$iuRest = Vue.$iuRest
  }
}

Vue.use(iuRest)

export default iuRest
