import _ from 'lodash';

//идентификатор портала
export const memberId = state => _.get(state, 'app.bx.member_id', '');

//идентификатор юзера на портале
export const bxUserId = state => _.get(state, 'user.bx.ID', 0);

// конфиг
export const conf = state => _.get(state, 'app.conf', {})

// ключ для api
export const apiKey = state => _.get(state, 'app.conf.apikey', '')

// баланс клиента
export const balance = state => _.get(state, 'app.balance', null)
