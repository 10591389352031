import Vue from 'vue';
import Vuex from 'vuex';

import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    app: {
      bx: {},
      config: {}
    }
  },
  getters,
  mutations,
  actions,
});

Vue.store = store // dirty

export default store;