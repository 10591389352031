/**
 * Объект конфига реста iu
 */
class Settings {
  constructor () {
    this.path = process.env.VUE_APP_PATH || 'api/';
  }

  setHost(host = '') {
    this.host = host
  }

  get endpointUrl() {
    return this.path
  }

}

export default new Settings