<template>
  <b-overlay :show="initializing" rounded="sm" :opacity="1" no-fade>
    <div id="app">
      <b-container class="content">
        <b-navbar class="justify-content-between app-top-bar">
          <b-navbar-brand >
            <img class="logo" src="@/assets/images/idigital-logo.svg"/>
          </b-navbar-brand>
          <div class="header">
            <h5>{{$t('header.title')}}</h5>
            <div>{{$t('header.descLine1')}}</div>
            <div>
              {{$t('header.descLine2_1')}}
              <a :href="loginUrl" target="_blank">{{ $t('header.descLinkText') }}</a>
              {{$t('header.descLine2_2')}}
            </div>

          </div>
        </b-navbar>
      <config></config>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import Config from "./components/Config";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      initializing: true,
      loggingOut: false,
      loginUrl: 'https://direct.i-dgtl.ru/login/?utm_source=bitrix24&utm_medium=referral&utm_campaign=sms-imform-sett'
    }
  },
  components: {
    Config,
  },
  mounted() {
    this.$b24.BX24.fitWindow()   //фикс для корректного отображения высоты фрейма в Б24 v20+
  },
  async created() {
    //инициализация данных хранилища
    try {
      await this.initB24AppData();
    } catch (e) {
      this.$log.error(e)
      this.$toast.open({
        message: `Error initializing app storage`,
        type: 'error'
      });
    } finally {
      this.initializing = false
    }
  },
  methods: {
    ...mapActions([
        'initB24AppData',
        'initB24UserData',
        'initCloudCallUserData',
    ]),

  },
  computed: {
    ...mapGetters([
        'ccAuthorized',
        'memberId'
    ])
  }
}
</script>

<style lang="scss">
  body {
    min-height: 1000px;
  }

  #app {
    font: 14px 'Pragmatica', 'Times New Roman';
    min-height: 1000px;
    background-color: #ebf0f4;
    padding: 10px;

    .content {

      max-width: 700px;

      .app-top-bar {
        justify-content: stretch;
        background-color: #ffffff;

        .header {
          line-height: 16px;
        }


        .logo {
          width: 100px;
        }
      }

    }



  }



</style>
