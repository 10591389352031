import Vue from 'vue'
import App from './App.vue'
import store from './store'

// плагины
import './plugins/b24/index'
import './plugins/iu-rest/index'
import './plugins/logger/index'
import './plugins/bootstrap/index'
import './plugins/font-awesome/index'
import './plugins/toast/index'
import i18n from './plugins/i18n/index'

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
