import Vue from 'vue'
import VueI18n from 'vue-i18n'
import queryString from 'query-string'
// langs
import en from './lang/en.json'
import ru from './lang/ru.json'
import es from './lang/es.json'
// pluralize
import './slavic_plural'

const $_GET = queryString.parse(location.search) // ❤ php
Vue.use(VueI18n);

// bitrix ⚣, ticket 2313067
($_GET['LANG'] === 'la') && ($_GET['LANG'] = 'es')

const instance = new VueI18n({
  locale: $_GET['LANG'],
  fallbackLocale: 'en',
  messages: { en, ru, es }
})
Vue.$t = instance // dirty

export default instance
