<template>
  <div class="balance" v-if="show">
    Баланс: {{ balance.value | money }} {{ currencySign }}
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex';

export default {
  name: "Balance",
  data() {
    return {
      balance: null,
    }
  },
  methods: {
    async loadBalance() {
      this.balance = null;
      this.$log.info(`Loading balance`);

      try {
        const result = await this.$iuRest.Request.create('balance.get', {
          apiKey: this.apiKey,
        }).execute()
        this.$log.info(`Balance load result `, result)
        this.balance = result
      } catch(e) {
        this.$log.warn(`Error updating balance `, e)
      }
      this.$log.info(`Balance loading finished`)
    }
  },
  computed: {
    ...mapGetters([
      'conf',
      'apiKey',
    ]),
    show() {
      return !_.isNull(this.balanceValue)
    },

    balanceValue() {
      return _.get(this, 'balance.value', null)
    },

    currencySign() {
      const currencyMap = {
        "RUB": '₽'
      }
      return currencyMap[_.get(this, 'balance.currency')] || ''
    }
  },
  filters: {
    money: function (value) {
      if (_.isNull(value)) return ''
      return value.toLocaleString(
          undefined,
          { minimumFractionDigits: 2 }
      );
    }
  },
  watch: {
    async apiKey() {
      await this.loadBalance();
    }
  }
}
</script>

<style scoped lang="scss">
  .balance {
    text-align: end;
  }

</style>