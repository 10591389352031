import Vue from 'vue'
import * as IuApi from './iu/index'

/**
 * Плагин B24, добавляет api методы b24 в каждый компонент, объект $b24
 */
const B24 = {

  install (Vue) {
    Vue.$b24 = IuApi
    Vue.prototype.$b24 = Vue.$b24
  }
}

Vue.use(B24)

window.BX24 = IuApi.BX24  // 4 debugging

export default B24
